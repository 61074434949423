import React from "react";
import { Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";
import { withAuthenticationRequired } from '@auth0/auth0-react';

import NonprofitSelector from "../NonprofitSelector";
import { nonprofitsQuery } from "./gql";

const Foyer = () => {
  const { data, loading } = useQuery(nonprofitsQuery);

  if(loading) {
    return null;
  }

  const nonprofits = pathOr([], ['nonprofits'], data);

  if(nonprofits.length === 1) {
    return <Navigate to={`/${nonprofits[0].id}/`} replace />
  }

  return <NonprofitSelector nonprofits={nonprofits} />
};

export default withAuthenticationRequired(Foyer);
