import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 12px;
`;

export const Header = styled.header`
  display: flex;
  padding: 0 6px;
`;

export const HeaderActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  > :not(:last-child) {
    margin-right: 18px;
  }
`;

export const Body = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 6px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 6px;
`;

export const FooterActions = styled.div`
  display: flex;
  gap: 12px;
`;

export const Heading = styled.div`
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 1fr auto;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const HeadingAside = styled.div`
  text-align: right;

  @media (max-width: 1024px) {
    text-align: unset;
  }
`;

export const Name = styled.div`
  font-size: 1.75em;
  font-weight: bold;
  color: #333333;
  font-family: tondo, sans-serif;
`;

export const Date = styled.div`
  font-size: 0.75em;
`;

export const Payment = styled.div`
  font-weight: bold;
  color: #666666;
  font-size: 0.75em;
`;

export const Amount = styled.div`
  position: relative;
  background-color: ${props => props.amount > 0 ? "#77aa16" : "#aaaaaa" };
  color: #ffffff;
  padding: 6px 24px;
  text-align: center;
  font-weight: bold;
  font-size: 1.75em;
  margin-bottom: 12px;
  border-radius: 6px;

  ${props => ["DISPUTED", "REFUNDED"].includes(props.status) && `
    background-color: #cccccc;

    &:after {
      content: "${props.status}";
      font-weight: bold;
      font-size: 0.5em;
      background-color: #db3a34;
      color: #ffffff;
      padding: 6px 12px;
      border-radius: 6px;
      position: absolute;
      top: 0;
      right: 12px;
      transform: translateY(-50%) rotate(-15deg);
    }
  `}
`;

export const TicketCount = styled.div`
  position: relative;
  border: 1px solid #aaaaaa;
  color: #666666;
  padding: 6px 24px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 12px;
  border-radius: 6px;
  font-size: 0.875em;

  ${props => ["DISPUTED", "REFUNDED"].includes(props.status) && `
    &:after {
      content: "Disqualified";
      font-weight: bold;
      font-size: 0.75em;
      background-color: #db3a34;
      color: #ffffff;
      padding: 6px 12px;
      border-radius: 6px;
      position: absolute;
      top: 0;
      right: 12px;
      transform: translateY(-50%) rotate(-15deg);
    }
  `}
`;

export const Field = styled.div`
  margin-bottom: 24px;
  padding: 0 6px;
`;

export const Label = styled.div`
  color: #666666;
  font-size: 0.75em;
`

export const Value = styled.div`
  color: #333333;
  padding-left: 6px;
`;

export const Address = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid #eee;
`;

export const TicketNumberList = styled.ol`
  list-style-type: none;
  margin: 0 -6px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-items: start;
`;

export const TicketNumberListItem = styled.li`
  min-width: 100px;
  flex: 1;
`;

export const TicketNumber = styled.li`
  border: 1px solid #eeeeee;
  color: #666666;
  padding: 6px;
  border-radius: 3px;
  text-align: center;
  font-weight: bold;
  margin: 6px;
`;

export const ReferenceId = styled.div`
  color: #aaa;
  font-size: 0.75em;
`;

export const ScannerInstructions = styled.div`
  margin-bottom: 12px;
  font-size: 0.875em;
`;

export const Scanner = styled.div`
  width: 100%;
  background-color: #ffffff;

  // This is a hack to make the viewfinder fill the entire space
  video ~ div > div {
    height: 100%;
    width: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
