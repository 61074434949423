import React from "react";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@apollo/client";
import { pathOr, path } from "ramda";
import { DateTime } from "luxon";

import { ScheduleGuard, ScheduleMask } from "../../../lib/schedule";
import AccessControlledButton from "../../../lib/AccessControlledButton";
import Button from "../../design/Button";
import ExportButton from "../../ExportButton";
import AutoApplyControls from "../AutoApplyControls";
import { Expiration } from "../utils";
import { showPromoCodeQuery } from "../gql";
import * as UI from "./ui";
import InsightCard from "./InsightCard";
import { shortDateTime } from "../../../util/date";

const Show = ({ promoCode: partialPromoCode, sweepstakes, showCancel, onCancel, setMode }) => {
  const { data, loading } = useQuery(showPromoCodeQuery, {
    variables: {
      sweepstakesId: sweepstakes.id,
      id: partialPromoCode.id,
    }
  });

  if (loading) {
    return null;
  }

  const promoCode = pathOr(partialPromoCode, ["sweepstakes", "promoCode"], data);
  const daily = path(["sweepstakes", "promoCode", "insights", "daily", "data"], data);
  const weekly = path(["sweepstakes", "promoCode", "insights", "weekly", "data"], data);
  const monthly = path(["sweepstakes", "promoCode", "insights", "monthly", "data"], data);
  const hasExpired = promoCode.expiresAt && (DateTime.fromISO(promoCode.expiresAt) < DateTime.local());

  const editButtonLockedAttributes = promoCode.locked ? { disabled: true, icon: faLock } : {};

  return (
    <UI.Layout>
      <UI.Header>
        { showCancel && (
          <Button size="small" onClick={onCancel}>❮ Close</Button>
        )}
        <UI.HeaderActions>
          <ScheduleMask enabledPeriods={["review", "open", "closing", "grace", "closed"]}>
            <AccessControlledButton size="small" onClick={() => setMode("edit")} resource="promoCodes" permission="U" {...editButtonLockedAttributes}>
              Edit Promo Code
            </AccessControlledButton>
          </ScheduleMask>
        </UI.HeaderActions>
      </UI.Header>

      <UI.Body>
        <UI.Heading>
          <UI.Name>{promoCode.name}</UI.Name>
          <UI.HeadingAside>
            <UI.Date>
              First Used: { promoCode.firstUsedAt ? shortDateTime(sweepstakes, promoCode.firstUsedAt) : (loading ? null : "Never") }
            </UI.Date>
            <UI.Date>
              <Expiration expiresAt={promoCode.expiresAt} sweepstakes={sweepstakes} />
            </UI.Date>
          </UI.HeadingAside>
        </UI.Heading>

        <UI.BonusPercent hasExpired={hasExpired}>
          {promoCode.bonusPercent}%
        </UI.BonusPercent>

        <UI.Insights>
          <InsightCard daily={daily} monthly={monthly} weekly={weekly} />
        </UI.Insights>

        {promoCode.notes && (
          <UI.Field>
            <UI.Label>Notes</UI.Label>
            <UI.Value>{promoCode.notes}</UI.Value>
          </UI.Field>
        )}

        { promoCode.autoApplyUrl && (
          <UI.Field>
            <ScheduleGuard enabledPeriods={["review", "open", "closing"]}>
              <AutoApplyControls autoApplyUrl={promoCode.autoApplyUrl} name={promoCode.name} />
            </ScheduleGuard>
          </UI.Field>
        )}
      </UI.Body>
      <UI.Footer>
        <UI.FooterActions>
          <ExportButton url={`/exports/${sweepstakes.id}/promo_code_entries.csv?promo_code_id=${partialPromoCode.id}`} resource="entries">
            Promo Code Donations List
          </ExportButton>
        </UI.FooterActions>
      </UI.Footer>
    </UI.Layout>
  );
}

export default Show;
