import styled from "styled-components";
import { Image } from "cloudinary-react";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #EDF6FB;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
  min-width: 320px;
`;

export const Title = styled.div`
  font-family: tondo, sans-serif;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
`;

export const SweepstakesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const SweepstakesItem = styled.div`
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
`;

export const SweepstakesImage = styled(Image)`
  display: block;
  border-radius: 3px 3px 0 0;
`;

export const SweepstakesName = styled.div`
  font-size: 0.75em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  color: #444;
  border-radius: 0 0 3px 3px;
  background-color: #fff;
  padding: 3px 6px;
  font-weight: bold;
`;
