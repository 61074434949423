import React from "react";

import Redactable from "../../../lib/Redactable";
import { formatAsDollars } from "../../../util/money";
import { sweepstakesDateTime } from "../../../util/date";
import ApplyPromoCode from "../../ApplyPromoCode";
import RefundManualEntryButton from "./RefundManualEntryButton";
import ResendReceiptButton from "./ResendReceiptButton";
import AccessControlledButton from "../../../lib/AccessControlledButton";
import Button from "../../design/Button";
import Telephone from "../../design/Telephone";
import * as UI from "./ui";

const formatAddressFirstLine = address => {
  if(!address) {
    return null;
  }

  if(address.line2) {
    return `${address.line1}, ${address.line2}`;
  }

  return address.line1;
}

const formatAddressSecondLine = address => {
  if(!address) {
    return null;
  }

  return `${address.city}, ${address.state} ${address.postalCode} ${address.country}`
}

const formatNumberOfTickets = entry => {
  if(entry.ticketCount === 1) {
    return "1 Entry";
  }

  return `${entry.ticketCount}  Entries`;
}

const Show = ({ entry, sweepstakes, showCancel, onCancel, setMode }) => {
  const editDisabled = !entry.email && !entry.phoneNumber && !entry.firstName && !entry.lastName;

  return (
    <UI.Layout>
      <UI.Header>
        { showCancel && (
          <Button size="small" onClick={onCancel}>❮ Close</Button>
        )}
        <UI.HeaderActions>
          <AccessControlledButton size="small" onClick={() => setMode("edit")} resource="entries" permission="U" disabled={editDisabled}>
            Edit Donation
          </AccessControlledButton>
        </UI.HeaderActions>
      </UI.Header>

      <UI.Body>
        <UI.Heading>
          <UI.Name>
            <Redactable>
              {entry.firstName} {entry.lastName}
            </Redactable>
          </UI.Name>
          <UI.HeadingAside>
            <UI.Date>{sweepstakesDateTime(sweepstakes, entry.createdAt)}</UI.Date>
            {entry.paymentInfo && <UI.Payment>Payment: {entry.paymentInfo}</UI.Payment>}
          </UI.HeadingAside>
        </UI.Heading>

        <UI.Amount amount={entry.amount} status={entry.status}>
          {entry.amount > 0 ? formatAsDollars(entry.amount) : "AMOE"}
        </UI.Amount>

        <UI.TicketCount ticketCount={entry.ticketCount} status={entry.status}>
          {formatNumberOfTickets(entry)}
        </UI.TicketCount>

        <UI.Field>
          <UI.Label>Entries</UI.Label>
          {entry.ticketRanges.map((range, index) => range.min === range.max ? (
            <UI.Value key={index}>#{range.min}</UI.Value>
          ) : (
            <UI.Value key={index}>{`#${range.min} through #${range.max}`}</UI.Value>
          ))}
        </UI.Field>

        <UI.Field>
          <UI.Label>Contact</UI.Label>
          <UI.Value>
            <Redactable>
              <a href={entry.email && `mailto:${entry.email}`}>
                {entry.email}
              </a>
            </Redactable>
          </UI.Value>
          <UI.Value>
            <Redactable>
              <Telephone phoneNumber={entry.phoneNumber} />
            </Redactable>
          </UI.Value>
        </UI.Field>

        <UI.Field>
          <UI.Label>Address</UI.Label>
          <UI.Value>
            <Redactable>
              <div>{formatAddressFirstLine(entry.address)}</div>
              <div>{formatAddressSecondLine(entry.address)}</div>
            </Redactable>
          </UI.Value>
        </UI.Field>

        { entry.status !== "FREE" && (
          <UI.Field>
            <UI.Label>Promo Code</UI.Label>
            <UI.Value>
              <ApplyPromoCode sweepstakes={sweepstakes} entry={entry} />
            </UI.Value>
          </UI.Field>
        )}

        { entry.notes && (
          <UI.Field>
            <UI.Label>Notes</UI.Label>
            <UI.Value>{entry.notes}</UI.Value>
          </UI.Field>
        )}

        <UI.Field>
          <UI.ReferenceId>Reference ID: {entry.id}</UI.ReferenceId>
        </UI.Field>
      </UI.Body>
      <UI.Footer>
        <UI.FooterActions>
          <RefundManualEntryButton entry={entry} />
          <ResendReceiptButton entryId={entry.id} />
        </UI.FooterActions>
      </UI.Footer>
    </UI.Layout>
  );
}

export default Show;
