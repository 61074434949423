import { gql } from "@apollo/client";

export const getRecentDonationActivity = gql`
  query getRecentDonationActivity($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      entries(size: 6) {
        id
        firstName
        lastName
        createdAt
        status
        amount
      }
    }
  }
`;
