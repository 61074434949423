import React from "react";
import { DateTime } from "luxon";

import { useMasterDetailContext } from "../../../lib/MasterDetail";
import { Expiration } from "../utils";
import * as UI from "./ui";

const Item = ({ item: promoCode }) => {
  const { bonusPercent, name, expiresAt } = promoCode;
  const { sweepstakes } = useMasterDetailContext();

  const hasExpired = expiresAt && (DateTime.fromISO(expiresAt) < DateTime.local());

  return (
    <UI.ItemLayout>
      <UI.Attributes>
        <UI.Heading hasExpired={hasExpired}>
          <UI.Name>{name}</UI.Name>
        </UI.Heading>
        <UI.Expiration>
          <Expiration expiresAt={expiresAt} sweepstakes={sweepstakes} />
        </UI.Expiration>
      </UI.Attributes>
      <UI.BonusPercent>{bonusPercent}%</UI.BonusPercent>
    </UI.ItemLayout>
  );
}

export default Item;
