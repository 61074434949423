import React from "react";
import { DateTime } from "luxon";

import { shortDate } from "../../../util/date";
import * as UI from "./ui";

const Item = ({ event, sweepstakes, sweepstakes: { timezone } }) => {
  const past = DateTime.fromISO(event.date, { zone: timezone }).startOf('day') < DateTime.local({ zone: timezone }).startOf('day');

  return (
    <UI.ItemLayout past={past} actionRequired={event.actionRequired}>
      <UI.Date>{shortDate(sweepstakes, event.date)}</UI.Date>
      <div>
        <UI.Summary $past={past}>{event.summary}</UI.Summary>
        {!past && (
          <UI.Description>
            {event.description}
          </UI.Description>
        )}
      </div>
    </UI.ItemLayout>
  );
}

export default Item;
