import React from "react";

import GuidedTour from "../../../lib/GuidedTour";
import { REQUIRED_STEPS, OPTIONAL_STEPS } from "./steps";

import RequestApproval from "./RequestApproval";

const OverviewPage = ({ sweepstakes }) => {
  return (
    <React.Fragment>
      <GuidedTour.Overview steps={REQUIRED_STEPS} sweepstakes={sweepstakes} heading="Setup Steps" />
      <GuidedTour.Overview steps={OPTIONAL_STEPS} sweepstakes={sweepstakes} heading="Optional Features" />
      <RequestApproval sweepstakes={sweepstakes} disabled={REQUIRED_STEPS.some(step => !step.isComplete({ sweepstakes }))} />
    </React.Fragment>
  );
};

export default OverviewPage;
