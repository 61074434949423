import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { pick, isNil } from "ramda";

import Formation from "../../forms/Form";
import TextField from "../../forms/TextField";
import Button from "../../design/Button";
import Money from "../../design/Money";
import { Context } from "../../../lib/Snackbar";
import { updateSweepstakesMutation } from "./gql";
import * as UI from "./ui";

const buildInitialInputObject = pick([
  'googleAnalyticsPropertyId',
  'googleAdsConversionId',
])

const AnalyticsIntegrationForm = ({ sweepstakes, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ updateSweepstakes, { loading }] = useMutation(updateSweepstakesMutation)
  const [ showAdvanced, setShowAdvanced ] = useState(false);

  const onSubmit = attributes => (
    updateSweepstakes({
      variables: {
        id: sweepstakes.id,
        attributes,
      }
    }).then(({ data }) => {
      if(isNil(data.updateSweepstakes.error)) {
        addMessage({ message: "Your sweepstakes has been successfully updated.", type: "success" });
        onStepFinish();
      } else {
        addMessage({ message: "There was a problem updating your sweepstakes.", type: "error" });
      }
    })
  );

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <Formation initialInputObject={buildInitialInputObject(sweepstakes)} onSubmit={onSubmit} loading={loading}>
          <TextField id="googleAnalyticsPropertyId" label="Google Analytics Property ID or Measurement ID" labelHint="Optional" />
          <TextField id="googleAdsConversionId" label="Google Ads Conversion ID" labelHint="Optional" />
          <Button type="submit">Save Analytics Integration</Button>
        </Formation>
        { sweepstakes.approved && (
          <UI.AdvancedSection>
            <Button onClick={() => setShowAdvanced(!showAdvanced)} size="small">{ showAdvanced ? "Hide" : "Show" } Advanced Info</Button>
            { showAdvanced && (
              <React.Fragment>
                <p>To help configure your marketing campaigns, we've provided a table with the Donation Amount, Google Analytics Item ID, Google Analytics Item Name, and Meta Content ID for each donation amount.</p>
                <UI.Table>
                  <thead>
                    <tr>
                      <th>Donation Amount</th>
                      <th>Google Analytics Item ID</th>
                      <th>GA Item Name</th>
                      <th>Meta Content ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    { sweepstakes.donationLevels.map(donationLevel => (
                      <tr key={donationLevel.id}>
                        <th><Money amount={donationLevel.price} /></th>
                        <td>{donationLevel.id}</td>
                        <td><Money amount={donationLevel.price} /> Donation</td>
                        <td>{donationLevel.id}</td>
                      </tr>
                    ))}
                  </tbody>
                </UI.Table>
              </React.Fragment>
            )}
          </UI.AdvancedSection>
        )}
      </UI.StepContent>
      <UI.Instructions>
        <h2>Analytics Integration</h2>
        <p>Your <strong>Google Analytics Property ID </strong> can be found in your Google Analytics account. It will look something like G-1234567890 or UA-12345678. This will help measure the number of visitors and donors on your Sweepstakes Site.</p>
        <p>Your <strong>Google Ads Conversion ID</strong> can be found in your Google Ads account. It will look something like AW-123456789.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
};

export default AnalyticsIntegrationForm;
