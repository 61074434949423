import React from "react";

import useField from "../../lib/useField";
import * as UI from "./ui";

const RadioField = ({ id, label, labelHint, options, ...rest }) => {
  const { value, errors, showErrors, skeleton, setValue } = useField(id);

  return (
    <UI.Fieldset>
      <UI.Legend>{label}</UI.Legend>
      {options.map(option => (
        <UI.RadioLayout key={`RadioOption-${option.id}`}>
          <UI.Radio
            id={option.id}
            name={option.id}
            onChange={setValue}
            value={option.id}
            checked={option.id === value}
            disabled={skeleton}
            invalid={showErrors}
            data-intercom-target={`${id}-${option.id}*`}
            {...rest}
          />
          <UI.RadioLabel htmlFor={option.id}>{option.label}</UI.RadioLabel>
        </UI.RadioLayout>
      ))}
      { showErrors && <UI.Errors>{errors}</UI.Errors> }
    </UI.Fieldset>
  )
};

export default RadioField;
