import React from "react";
import { ReactComponent as Image } from "./logo.svg";

import * as UI from "./ui";

const Logo = () => (
  <UI.LogoLink to="/">
    <Image />
  </UI.LogoLink>
);

export default Logo;
