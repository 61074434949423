import React from "react";
import { useParams } from "react-router-dom";
import { faCalendar, faCode, faDonate, faUsers, faTags, faChartArea, faMedal } from '@fortawesome/free-solid-svg-icons'

import { hotkeys } from "../SweepstakesPages/Hotkeys";
import AccessControlledLink from "../../lib/AccessControlledLink";
import * as UI from "./ui";

const SweepstakesMenu = ({ sweepstakes, showHotkeys }) => {
  const { nonprofitId } = useParams();
  const prefix = `/${nonprofitId}/sweepstakes/${sweepstakes.id}`;

  return (
    <UI.Layout>
      <UI.Title>{sweepstakes.prize.name}</UI.Title>
      <UI.Nav>
        <UI.MenuGroup>
          <AccessControlledLink icon={faUsers} to={`${prefix}/donations`} resource="entries" hotkey={showHotkeys && hotkeys.donations.keys[0]}>Donations</AccessControlledLink>
          <AccessControlledLink icon={faTags} to={`${prefix}/promo-codes`} resource="promoCodes" hotkey={showHotkeys && hotkeys.promoCodes.keys[0]}>Promo Codes</AccessControlledLink>
          <AccessControlledLink icon={faChartArea} to={`${prefix}/insights`} resource="insights" hotkey={showHotkeys && hotkeys.insights.keys[0]}>Sweepstakes Insights</AccessControlledLink>
          <AccessControlledLink icon={faMedal} to={`${prefix}/flash-contests`} resource="flashContests" hotkey={showHotkeys && hotkeys.flashContests.keys[0]}>Flash Contests</AccessControlledLink>
          <AccessControlledLink icon={faCalendar} to={`${prefix}/marketing`} resource="sweepstakes">Email Smart Schedule</AccessControlledLink>
        </UI.MenuGroup>
        <UI.MenuGroup>
          <AccessControlledLink icon={faDonate} to={`${prefix}/site`} resource="sweepstakes" permission="U" hotkey={showHotkeys && hotkeys.site.keys[0]}>Sweepstakes Setup</AccessControlledLink>
          <AccessControlledLink icon={faCode} to={`${prefix}/plugin`} resource="sweepstakes" permission="R">Donation Plugin</AccessControlledLink>
        </UI.MenuGroup>
        <UI.MenuGroup>
          <AccessControlledLink icon={faMedal} to={`${prefix}/winner`} resource="winners" permission="U">Draw the Winner</AccessControlledLink>
        </UI.MenuGroup>
      </UI.Nav>
    </UI.Layout>
  );
}

export default SweepstakesMenu;
