import React from "react";
import { useParams } from "react-router-dom";

import { withAccessControl } from "../../lib/permissions";
import FlashContestList from "../FlashContestList";

const FlashContestPage = () => {
  const { sweepstakesId } = useParams();

  return (
    <React.Fragment>
      <FlashContestList sweepstakesId={sweepstakesId} />
    </React.Fragment>
  );
}

export default withAccessControl({ resource: "flashContests" })(FlashContestPage);
