import React, { useRef, useEffect } from "react";

import * as UI from "./ui";

const MasterList = ({ items, selectedItem, mode, onSelect, ListItemComponent }) => {
  const listNode = useRef(null);

  useEffect(() => {
    if(listNode.current) {
      listNode.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [ items ]);

  return (
    <UI.MasterList mode={mode} innerRef={listNode}>
      { items.map(item => (
        <UI.MasterItem key={item.id} onClick={() => onSelect(item.id)} selected={selectedItem && item.id === selectedItem.id}>
          <ListItemComponent item={item} />
        </UI.MasterItem>)
      )}
    </UI.MasterList>
  );
}

export default MasterList;
