import React, { useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { prepend, isNil, evolve, path } from "ramda";

import { sweepstakesPromoCodesQuery, createPromoCodeMutation } from "../gql";
import { Context as SnackbarContext } from "../../../lib/Snackbar";

import PromoCodeForm from "../../PromoCodeForm";

const prependOnSuccess = sweepstakesId => (cache, results) => {
  const promoCode = path(['data', 'createPromoCode', 'promoCode'], results);

  if(isNil(promoCode)) {
    return;
  }

  const config = {
    query: sweepstakesPromoCodesQuery,
    variables: { sweepstakesId },
  };

  const data = cache.readQuery(config);

  const evolution = {
    sweepstakes: {
      promoCodes: prepend(promoCode),
    }
  };

  cache.writeQuery({
    ...config,
    data: evolve(evolution, data),
  });
};

const NewForm = ({ guarded, sweepstakes, onSelect }) => {
  const [ createPromoCode, { loading } ] = useMutation(createPromoCodeMutation, {
    update: prependOnSuccess(sweepstakes.id),
  });
  const { addMessage } = useContext(SnackbarContext);

  useEffect(() => {
    if (guarded) {
      addMessage({ type: "error", message: "The Sweepstakes has closed so you can not add new Promo Codes." })
    }
  }, [ addMessage, guarded ]);

  const onSubmit = inputObject => (
    createPromoCode({
      variables: {
        sweepstakesId: sweepstakes.id,
        attributes: inputObject,
      },
    }).then(({ data }) => {
      const promoCode = path(['createPromoCode', 'promoCode'], data);

      if(promoCode) {
        onSelect(promoCode.id);
      } else {
        addMessage({ type: "error", message: "An error occurred during form submission." });
      }
    })
  );

  return (
    <React.Fragment>
      <PromoCodeForm
        sweepstakesId={sweepstakes.id}
        initialInputObject={{}}
        onSubmit={onSubmit}
        inUse={false}
        zone={sweepstakes.timezone}
        loading={loading}
        guarded={guarded}
      />
    </React.Fragment>
  );
};

export default NewForm;
