import React from "react";
import { path, propOr } from "ramda";

import useField from "../../lib/useField";
import useUploadWidget from "../../lib/useUploadWidget";
import Button from "../design/Button";
import RadioButtonControl from "../design/RadioButtonControl";
import ProgressBar from "../design/ProgressBar";
import * as UI from "./ui";

const Status = ({ name, status }) => (
  <UI.UploadStatus>
    {name}
    <span style={{padding: 3, color: status ? "#77aa16" : "#db3a34" }}>
      {status ? "✓" : "✖"}
    </span>
  </UI.UploadStatus>
);

const NOOP_CROP = {
  strategy: {}
};

const ImageUploadField = ({ id, label, labelHint, owner, previewProps, directory, crops, ...rest }) => {
  const { value, errors, showErrors, setValue, touched, touch } = useField(id);
  const subfolder = directory || id.replace(".", "-");
  const folder = `assets/${owner}/${subfolder}`;
  const { publicId, version, cloudName, crop, gravity, background } = value ? JSON.parse(value) : {};
  const defaultOption = crop || path([0, 'id'], crops);

  const handleUpload = ({ public_id: publicId, version }) => {
    touch();

    const crop = propOr(NOOP_CROP, 0, crops);

    setValue({
      target: {
        value: JSON.stringify({ 
          publicId,
          version,
          cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
          ...crop.strategy,
        })
      }
    });
  };

  const setCrop = id => {
    const crop = crops.find(crop => crop.id === id);

    if(!crop) {
      return;
    }

    touch();

    setValue({
      target: {
        value: JSON.stringify({
          publicId,
          version,
          cloudName,
          ...crop.strategy,
        })
      }
    });
  };

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    uploadProgress,
  } = useUploadWidget({
    folder,
    uploadPreset: "assets",
    onUpload: handleUpload,
  });

  return (
    <UI.FieldLayout data-intercom-target={`${id}*`}>
      <UI.UploadLayout {...getRootProps()}>
        <UI.UploadToolbar>
          <Button size="tiny" type="button" onClick={open}>New Image</Button>
          {crops && <RadioButtonControl key={publicId} name={`${id}-crop`} options={crops} defaultOption={defaultOption} onChange={setCrop} />}
          <UI.UploadStatusBar>
            <Status name="Uploaded" status={!!publicId} />
            <Status name="Saved" status={!!publicId && !touched} />
          </UI.UploadStatusBar>
        </UI.UploadToolbar>

        <UI.Preview
          cloudName={cloudName}
          publicId={publicId}
          version={version}
          crop={crop}
          gravity={gravity}
          background={background}
          {...previewProps}
        />

        <UI.InProgress isUploadActive={uploadProgress}>
          <ProgressBar percent={uploadProgress} />
        </UI.InProgress>

        <UI.Dropzone isDragActive={isDragActive}>
          Drop Files Here
        </UI.Dropzone>
        <input {...getInputProps()} />
      </UI.UploadLayout>
      <UI.Label htmlFor={id} labelHint={labelHint}>{label}</UI.Label>
      { showErrors && <UI.Errors>{errors}</UI.Errors> }
    </UI.FieldLayout>
  );
};

export default ImageUploadField;
