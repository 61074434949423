import React from "react";
import { Routes, Route } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { nonprofitQuery } from "./gql";
import * as UI from "./ui";

const Header = ({ nonprofitId }) => {
  const { data } = useQuery(nonprofitQuery, {
    variables: { id: nonprofitId },
  });

  const nonprofit = data?.nonprofit;

  return (
    <UI.Header>
      <UI.Heading>
        <Routes>
          <Route index element={<UI.Title>Team</UI.Title>} />
          <Route path="invoices" element={<UI.Title>Manual Donation Invoices</UI.Title>} />
          <Route path="preferences" element={<UI.Title>Email Preferences</UI.Title>} />
        </Routes>
        <UI.Name>{nonprofit?.name}</UI.Name>
      </UI.Heading>
    </UI.Header>
  );
};

export default Header;
