import React, { useCallback, useContext } from "react";
import { useMutation } from "@apollo/client";
import { path } from "ramda";
import { DateTime } from "luxon";

import PromoCodeForm from "../../PromoCodeForm";
import { Context as SnackbarContext } from "../../../lib/Snackbar";
import { updatePromoCodeMutation } from "../gql";

const EditForm = ({ promoCode, sweepstakes, setMode, guarded }) => {
  const [ updatePromoCode, { loading } ] = useMutation(updatePromoCodeMutation);
  const { addMessage } = useContext(SnackbarContext);

  const onSubmit = useCallback(inputObject => (
    updatePromoCode({
      variables: {
        sweepstakesId: sweepstakes.id,
        id: promoCode.id,
        attributes: inputObject,
      },
    }).then(({ data }) => {
      const promoCode = path(['updatePromoCode', 'promoCode'], data);

      if(promoCode) {
        setMode("show");
      } else {
        addMessage({ type: "error", message: "An error occurred during form submission." });
      }
    })
  ), [ addMessage, updatePromoCode, setMode, sweepstakes, promoCode ])

  const inputObject = {
    name: promoCode.name,
    bonusPercent: promoCode.bonusPercent,
    expirationDate: DateTime.fromISO(promoCode.expiresAt).toISODate(),
    notes: promoCode.notes,
  };

  return (
    <React.Fragment>
      <PromoCodeForm
        sweepstakesId={sweepstakes.id}
        initialInputObject={inputObject}
        onSubmit={onSubmit}
        zone={sweepstakes.timezone}
        inUse={promoCode.inUse}
        loading={loading}
        guarded={guarded}
      />
    </React.Fragment>
  );
};

export default EditForm;
