import React, { useState } from "react";

import AccessControlledButton from "../../lib/AccessControlledButton";
import Form from "./Form";
import * as UI from "./ui";
import { ScheduleMask } from "../../lib/schedule";
import { isPresent } from "../../util/presence";

const ApplyPromoCode = ({ entry, sweepstakes }) => {
  const [ showForm, setShowForm ] = useState(false);

  if (isPresent(entry.promoCode)) {
    return entry.promoCode.name
  }

  return (
    <React.Fragment>
      {showForm ? (
        <Form sweepstakes={sweepstakes} entry={entry} />
      ) : (
        <React.Fragment>
          <UI.NoPromoCode>NONE</UI.NoPromoCode>
          <ScheduleMask enabledPeriods={["open", "closing", "grace", "closed"]}>
            <AccessControlledButton size="tiny" onClick={() => setShowForm(true)} resource="entries" permission="U">
              Add Promo Code
            </AccessControlledButton>
          </ScheduleMask>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ApplyPromoCode;
