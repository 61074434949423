import React from "react";

import CodeBlock from "../design/CodeBlock";
import Button from "../design/Button";

import * as UI from "./ui";

const scriptPrefix = `<script src="${process.env.REACT_APP_PLUGIN_URL}`;
const scriptSuffix = ` type="text/javascript"></script>`;

const buildIntegrations = configuration =>
  [
    configuration.facebook ? `${scriptPrefix}/integrations/fb"${scriptSuffix}` : '',
    configuration.googleAnalytics ? `${scriptPrefix}/integrations/gtag"${scriptSuffix}` : '',
    configuration.googleAds ? `${scriptPrefix}/integrations/googleAds/${configuration.googleAdsCampaign}"${scriptSuffix}` : '',
    `${scriptPrefix}/integrations/autoApplyPromoCode"${scriptSuffix}`,
  ].filter(item => item).join('\n')
;

const PluginCode = ({ pluginUrl, configuration, back }) => (
  <UI.CodeLayout>
    <UI.Section>
      <CodeBlock code={`
          <iframe
            src="${pluginUrl}"
            style="border:none;min-height:500px;min-width:320px;width:100%"
            frameborder="0"></iframe>
        `.replace(/\s+/g, ' ').trim()}
      />
    </UI.Section>
    <UI.Section>
      <CodeBlock code={buildIntegrations(configuration)} />
    </UI.Section>
    <Button onClick={back} size="small">Edit Plugin Configuration</Button>
  </UI.CodeLayout>
);

export default PluginCode;
