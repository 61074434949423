import React, { useState } from "react";
import styled from "styled-components";

const Layout = styled.ol`
  list-style-type: none;
  margin: 0;
  display: flex;
  gap: 6px;
`;

const Option = styled.li`
  display: block;
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  display: inline-block;
  color: #333333;
  font-size: 0.75em;
  padding: 3px 6px;
  cursor: pointer;
  border-radius: 6px;
  font-weight: bold;
  white-space: nowrap;

  ${Input}:checked + & {
    background-color: ${props => props.selectedColor || "#ffffff"};
  }
`

const RadioControl = ({ name, options, defaultOption, onChange, selectedColor = "#fff", ...rest }) => {
  const [ value, setValue ] = useState(defaultOption);

  const handleChange = e => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue);
  };


  return (
    <Layout {...rest}>
      {options.map(option => (
        <Option key={option.id}>
          <Input
            type="radio"
            name={name}
            id={option.id}
            value={option.id}
            checked={option.id === value}
            onChange={handleChange}
          />
          <Label htmlFor={option.id} selectedColor={selectedColor}>{option.name}</Label>
        </Option>
      ))}
    </Layout>
  );
};

export default RadioControl;
