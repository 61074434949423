import styled from "styled-components";
import Button from "../design/Button";

export const HelpButton = styled(Button)`
  @media (max-width: 480px) {
    display: none;
  }
`;

export const TourButton = styled(Button)`
  @media (max-width: 730px) {
    display: none;
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: 12px;
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #EDF6FB;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
  min-width: 320px;
`;

export const Title = styled.div`
  font-family: tondo, sans-serif;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`;

export const Nonprofits = styled.div`
  max-height: 50vh;
  overflow-y: auto;
`;

export const Nonprofit = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
`;

export const Info = styled.div`
  flex: 1;
`;

export const NonprofitName = styled.div`
  font-size: 0.875em;
  font-weight: bold;
`;

export const RoleName = styled.div`
  font-size: 0.75em;
`;

export const Controls = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;
