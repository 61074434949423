import React, { useContext } from "react";
import { DateTime } from "luxon";
import { useMutation } from "@apollo/client";
import { isNil, pick } from "ramda";

import { updateSweepstakesMutation } from "./gql";
import WinningTicketDrawnAtField from "./WinningTicketDrawnAtField";
import Formation from "../../forms/Form";
import TextField from "../../forms/TextField";
import DateTimeField from "../../forms/DateTimeField";
import Button from "../../design/Button";
import { presence } from "../../../lib/validations";
import { Context } from "../../../lib/Snackbar";
import * as UI from "./ui";

const validations = {
  endsAt: presence,
  winningTicketDrawnAt: presence,
  location: presence,
};

const buildInitialInputObject = ({ location, endsAt, winningTicketDrawnAt }) => ({
  location,
  endsAt,
  winningTicketDrawnAt,
});

const DrawingDetailsForm = ({ sweepstakes, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ updateSweepstakes, { loading } ] = useMutation(updateSweepstakesMutation)

  const buildSubmissionObject = sweepstakes.approved ?
    pick(['location']) :
    pick(['endsAt', 'winningTicketDrawnAt', 'location']);

  const onSubmit = inputObject => (
    updateSweepstakes({
      variables: {
        id: sweepstakes.id,
        attributes: buildSubmissionObject(inputObject),
      }
    }).then(({ data }) => {
      if(isNil(data.updateSweepstakes.error)) {
        addMessage({ message: "Your sweepstakes has been successfully updated.", type: "success" });
        onStepFinish();
      }
    })
  );

  const minEndsAt = sweepstakes.approved ?
    sweepstakes.endsAt : DateTime.fromObject({}, { zone: sweepstakes.timezone }).toISO();

  const setDefaultEndsAtTime = value => value.endOf('day');

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <Formation initialInputObject={buildInitialInputObject(sweepstakes)} onSubmit={onSubmit} loading={loading} validations={validations}>
          <DateTimeField id="endsAt" label="Close Sweepstakes Date & Time" zone={sweepstakes.timezone} minDate={minEndsAt} disabled={sweepstakes.approved} setDefaultTime={setDefaultEndsAtTime} />
          <WinningTicketDrawnAtField id="winningTicketDrawnAt" label="Draw the Winner Date & Time" sweepstakes={sweepstakes} baseId="endsAt" disabled={sweepstakes.approved} />
          <TextField id="location" label="Drawing the Winner Location" />
          <Button type="submit">Save & Continue</Button>
        </Formation>
      </UI.StepContent>
      <UI.Instructions>
        <h2>Schedule and Location</h2>
        <p>The <strong>Close Sweepstakes Date & Time</strong> is when the sweepstakes has closed and no longer accepts new entries. Any donors in the process of making a donation are given 10 minutes past the close time to complete their donation.</p>
        <p>The <strong>Draw the Winner Date & Time</strong> is when you will draw the winner for this campaign. The Date is automatically set to 5 business days after your sweepstakes close date to allow all mail-in entries to be received. You can choose a different date to draw the winner provided it is more than 5 business days after your close date. You may choose any time to draw your winner.</p>
        <p>Your <strong>Draw the Winner Location</strong> can be a link to an online site (such as a Facebook page) or a physical address/location such an event location or your nonprofit offices.</p>
        <p><strong>Note:</strong> Dates and times can not be changed once your sweepstakes has been approved. The Draw the Winner Location can be updated at any point during the sweepstakes.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
};

export default DrawingDetailsForm;
