import React from "react";
import { useParams, Navigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useMutation } from "@apollo/client";
import { append, evolve, isNil, path } from "ramda";

import Formation from "../forms/Form";
import TextField from "../forms/TextField";
import Button from "../design/Button";
import { presence } from "../../lib/validations";
import { createSweepstakesMutation } from "./gql";
import { setupDashboardQuery } from "../Dashboard/gql";

const validations = {
  prizeName: presence,
};

const appendOnSuccess = nonprofitId => (cache, results) => {
  const sweepstakes = path(['data', 'createSweepstakes', 'sweepstakes'], results);

  if(isNil(sweepstakes)) {
    return;
  }

  const config = {
    query: setupDashboardQuery,
    variables: { nonprofitId },
  };

  const data = cache.readQuery(config);

  const evolution = {
    nonprofit: {
      sweepstakes: append(sweepstakes),
    }
  };

  cache.writeQuery({
    ...config,
    data: evolve(evolution, data),
  });
};

const NewSweepstakesForm = () => {
  const { nonprofitId } = useParams();
  const [ createSweepstakes, { data, loading }] = useMutation(createSweepstakesMutation, {
    update: appendOnSuccess(nonprofitId),
  });

  const initialInputObject = {
    timezone: DateTime.local().zoneName,
  };

  const onSubmit = attributes => (
    createSweepstakes({
      variables: {
        nonprofitId,
        attributes,
      }
    })
  );

  const sweepstakes = path(['createSweepstakes','sweepstakes'], data);

  if(sweepstakes) {
    return <Navigate to={`../sweepstakes/${sweepstakes.id}/site`} />
  }

  return (
      <Formation initialInputObject={initialInputObject} onSubmit={onSubmit} validations={validations}>
        <TextField id="prizeName" label="Prize Name" />
        <Button type="submit" size="small" disabled={loading}>Proceed to Setup</Button>
      </Formation>
  );
}

export default NewSweepstakesForm;
