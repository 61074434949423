import React, { useState } from "react";
import { find, propEq } from "ramda";
import { arrayMove } from "@dnd-kit/sortable";
import { v4 as uuid } from 'uuid';

import useDraftableState from "../useDraftableState";
import Header from "./Header";
import Collection from "./Collection";
import Editor from "./Editor";
import * as UI from "./ui";

export const NEW_ITEM = { id: "__NEW__" };

const findSelectedItem = (items, id) => id === NEW_ITEM.id ? NEW_ITEM : find(propEq('id', id), items);

const CollectionEditor = ({
  items: initialItems,
  newItemLabel,
  submitLabel,
  draftNotice,
  discardDraftLabel,
  itemLabel,
  onSubmit,
  minItems = 0,
  maxItems = Number.POSITIVE_INFINITY,
  draftKey,
  ItemComponent,
  EditorComponent
}) => {
  const [ items, setItems, draft ] = useDraftableState(initialItems, draftKey);
  const [ selectedItemId, setSelectedItemId ] = useState(null);
  const selectedItem = findSelectedItem(items, selectedItemId)

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setItems(items => {
        const oldIndex = items.findIndex(propEq('id', active.id));
        const newIndex = items.findIndex(propEq('id', over.id));
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const appendItem = item => {
    const newItem = { ...item, id: uuid() };
    setItems(currentItems => [ ...currentItems, newItem ]);

    setSelectedItemId(null);
  }

  const updateSelectedItem = updatedItem => {
    const index = items.findIndex(propEq('id', selectedItemId));
    setItems(currentItems => {
      const nextItems = [...currentItems];
      nextItems[index] = updatedItem;
      return nextItems;
    });

    setSelectedItemId(null);
  }

  const handleRemoval = removedItem => {
    if(window.confirm("Are you sure you want to remove this?")) {
      const index = items.findIndex(propEq('id', removedItem.id));
      setItems(currentItems => {
        const nextItems = [...currentItems];
        nextItems.splice(index, 1);
        return nextItems;
      });
    }
  };

  const handleDone = item => {
    item.id === NEW_ITEM.id ? appendItem(item) : updateSelectedItem(item)
  };

  const handleSubmit = () => {
    draft.clear();
    return onSubmit(items);
  }

  return (
    <UI.Layout>
      { newItemLabel && (
        <Header
          newItemLabel={newItemLabel}
          newItemEnabled={items.length < maxItems}
          draftNotice={draftNotice}
          discardDraftLabel={discardDraftLabel}
          setSelectedItemId={setSelectedItemId}
          submitLabel={submitLabel}
          onSubmit={handleSubmit}
          draft={draft}
        />
      )}
      <Collection
        items={items}
        handleDragEnd={handleDragEnd}
        onSelect={setSelectedItemId}
        onRemove={handleRemoval}
        removeEnabled={items.length > minItems}
        itemLabel={itemLabel}
        ItemComponent={ItemComponent}
      />
      <Editor
        item={selectedItem}
        onSelect={setSelectedItemId}
        onDone={handleDone}
        EditorComponent={EditorComponent}
      />
    </UI.Layout>
  );
};

export default CollectionEditor;
