import styled from "styled-components";
import { Link } from "react-router-dom";

export const Layout = styled.div`
  position: relative;
  height: 100dvh;
  background-color: #edf6fb;
  display: grid;
  grid-template-rows: auto 1fr;
`;

export const Header = styled.header`
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  background-color: #fff;
`;

export const HeaderItem = styled.div`
  ${({ $align }) => $align && `justify-self: ${$align};`}
`;

export const LogoLink = styled(Link)`
  display: block;
  height: 24px;
  
  svg {
    height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  z-index: 0;
  padding: 0 12px;
  overflow-y: auto;
`;
