import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";

import Header from "./Header";
import SweepstakesList from "./SweepstakesList";
import { setupDashboardQuery } from "./gql";
import * as UI from "./ui";

const Dashboard  = () => {
  const { nonprofitId } = useParams();
  const { data } = useQuery(setupDashboardQuery, {
    variables: {
      nonprofitId,
    }
  });

  const sweepstakes = pathOr([], ['nonprofit', 'sweepstakes'], data);

  return (
    <UI.Layout>
      <Header hasSweepstakes={sweepstakes.length > 0} />
      { sweepstakes.length > 0 && (
        <SweepstakesList sweepstakes={sweepstakes} />
      )}
    </UI.Layout>
  );
}

export default Dashboard;
