import { gql } from "@apollo/client";

export const nonprofitRolesQuery = gql`
  query nonprofitRoles {
    session {
      id
      connection
    }

    nonprofits {
      id
      name

      profile {
        id
        website
      }

      myRole {
        id
        name
        nonprofit
        team
        sweepstakes
        winners
        entries
        promoCodes
        flashContests
        insights
        invoices
        paymentAccount
        sweepstakesRetrospectiveReports
      }
    }
  }
`;
