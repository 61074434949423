import React from "react";
import { Routes, Route, Link } from "react-router-dom";

import { REQUIRED_STEPS, OPTIONAL_STEPS } from "../SweepstakesEditPage/Editor/steps";
import * as UI from "./ui";

const Heading = ({ title }) => (
  <UI.Heading>
    <Routes>
      <Route index                    element={<UI.Title>Overview</UI.Title>} />
      <Route path="donations"         element={<UI.Title>Donations</UI.Title>} />
      <Route path="promo-codes"       element={<UI.Title>Promo Codes</UI.Title>} />
      <Route path="winner"            element={<UI.Title>Draw the Winner</UI.Title>} />
      <Route path="insights"          element={<UI.Title>Sweepstakes Insights</UI.Title>} />
      <Route path="flash-contest"     element={<UI.Title>Flash Contest</UI.Title>} />
      <Route path="flash-contests"    element={<UI.Title>Flash Contests</UI.Title>} />
      <Route path="marketing"         element={<UI.Title>Email Smart Schedule</UI.Title>} />
      <Route path="plugin/*"          element={<UI.Title>Donation Plugin</UI.Title>} />
      <Route path="site"              element={<UI.Title>Sweepstakes Setup</UI.Title>} />
      <Route path="retro"             element={<UI.Title>Retrospective Report</UI.Title>} />

      { REQUIRED_STEPS.map(({ id, name }) => <Route key={id} path={`site/${id}`} element={<UI.Title><Link to="../site">Setup</Link>{name}</UI.Title>} />)}
      { OPTIONAL_STEPS.map(({ id, name }) => <Route key={id} path={`site/${id}`} element={<UI.Title><Link to="../site">Setup</Link>{name}</UI.Title>} />)}
    </Routes>
    <UI.Name to="./">{title}</UI.Name>
  </UI.Heading>
);

export default Heading;
