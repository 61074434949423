import React from "react";
import { Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import AtAGlance from "./AtAGlance";
import MostActivePromoCodes from "./MostActivePromoCodes";
import Hints from "./Hints";
import FlashContestOverview from "./FlashContestOverview";
import RecentDonations from "./RecentDonations";
import MarketingSchedule from "./MarketingSchedule";
import Retrospective from "./Retrospective";
import Winner from "./Winner";
import { useSchedulePeriod } from "../../lib/schedule";
import { usePermissions } from "../../lib/permissions";
import { sweepstakesOverviewQuery } from "./gql";
import * as UI from "./ui";

const showWinnerCTA = ({ canDrawWinner, sweepstakes, period }) =>
  canDrawWinner && period === "drawn" && (!sweepstakes?.prize?.win || sweepstakes.prize.win.confirmedAt === null);

const SweepstakesPage = ({ sweepstakes: { id } }) => {
  const { data, loading, error } = useQuery(sweepstakesOverviewQuery, { variables: { id } });
  const period = useSchedulePeriod();
  const canDrawWinner = usePermissions({ resource: "winners", permission: "C" });

  if(loading || error) return null;

  const sweepstakes = data?.sweepstakes;

  if(showWinnerCTA({ canDrawWinner, sweepstakes, period })) {
    return <Navigate to="./winner" replace />;
  }

  if(period === "drawn") {
    return (
      <UI.DrawnLayout>
        <AtAGlance sweepstakes={sweepstakes} />
        <Winner sweepstakes={sweepstakes} />
        <Retrospective sweepstakes={sweepstakes} />
        <Hints />
      </UI.DrawnLayout>
    )
  }

  return (
    <UI.Layout>
      <AtAGlance sweepstakes={sweepstakes} />
      <MostActivePromoCodes sweepstakes={sweepstakes} />
      <FlashContestOverview sweepstakes={sweepstakes} />
      <Hints />
      <RecentDonations sweepstakes={sweepstakes} />
      <MarketingSchedule sweepstakes={sweepstakes} />
    </UI.Layout>
  );
};

export default SweepstakesPage;
