import React, { useCallback, useContext } from "react";
import { path } from "ramda";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import SectionHeader from "../design/SectionHeader";
import { applyForAccountMutation } from "./gql";
import Form from "../forms/Form";
import Button from "../design/Button";
import TextField from "../forms/TextField";

import { isPresent } from "../../util/presence";
import { Context as SnackbarContext } from "../../lib/Snackbar";
import { presence, formatOf, isLink } from "../../lib/validations";
import * as UI from "./ui";

const validations = {
  firstName: presence,
  lastName: presence,
  email: presence,
  nonprofitName: presence,
  ein: formatOf({
    pattern: /^\d{2}-?\d{7}$/,
    message: "This does not appear to be a valid EIN."
  }),
  website: isLink,
};

const initialInputObject = {
  website: "https://",
};

const Apply = () => {
  const [ applyForAccount, { loading } ] = useMutation(applyForAccountMutation);
  const { addMessage } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const onSubmit = useCallback(attributes => (
    applyForAccount({
      variables: {
        attributes
      }
    }).then(({ data, error }) => {
      const accountApplicationId = path(['applyForAccount','accountApplication','id'], data);

      if (isPresent(accountApplicationId)) {
        navigate("success", { state: { accountApplicationId }})
      } else {
        addMessage({ type: "error", message: "There was a problem submitting your application, please try again" });
      }
    })), [ addMessage, applyForAccount, navigate ]);

  return (
    <React.Fragment>
      <SectionHeader heading="Apply for a TapKat Nonprofit Account" />
      <p>Welcome! Get started on TapKat by telling us a little bit about you and your nonprofit organization.</p>
      <Form initialInputObject={initialInputObject} onSubmit={onSubmit} validations={validations}>
        <UI.FieldGroup>
          <TextField id="firstName" label="First Name" />
          <TextField id="lastName" label="Last Name" />
        </UI.FieldGroup>
        <UI.FieldGroup>
          <TextField id="email" type="email" label="Email Address" />
        </UI.FieldGroup>
        <UI.FieldGroup>
          <TextField id="nonprofitName" label="Nonprofit Organization" />
          <TextField id="ein" label="Nonprofit EIN#" />
          <TextField id="website" label="Nonprofit Website" />
        </UI.FieldGroup>
        <UI.Legal>
          By clicking “Apply” or signing up you agree to <a href="https://tapkat.com/terms-of-service" target="_blank" rel="noopener noreferrer">TapKat Terms of Service</a>, <a href="https://www.tapkat.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, <a href="https://tapkat.com/copyright-policy" target="_blank" rel="noopener noreferrer">Copyright Policy</a> and <a href="https://tapkat.com/standards-of-conduct" target="_blank" rel="noopener noreferrer">Standards of Conduct</a>. You also agree to receive information and offers relevant to our services via email.  You can opt-out of these emails anytime by clicking unsubscribe at the bottom of the email.
        </UI.Legal>
        <Button type="submit" disabled={loading}>Agree and Apply</Button>
      </Form>
    </React.Fragment>
  );
};

export default Apply;

