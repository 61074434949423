import React from "react";
import { DateTime } from "luxon";
import { evolve } from "ramda";

import DateBlacklist from "../../util/DateBlacklist";
import { default as LibraryForm } from "../forms/Form";
import TextField from "../forms/TextField";
import DateRangeField from "../forms/DateRangeField";
import ImageUploadField from "../forms/ImageUploadField";
import Button from "../design/Button";
import { combineValidations, presence, dateExclusion } from "../../lib/validations";
import * as UI from "./ui";

const DEFAULT_VALIDATIONS = {
  name: presence,
  announcement: presence,
  range: presence,
};

const previewProps = {
  aspectRatio: "16:9",
  width: "600",
  fetchFormat: "auto",
  style: {
    maxWidth: '600px',
    width: '100%',
  },
};

const CROP_OPTIONS = [
  {
    id: 'fill',
    name: 'Crop to Fit',
    strategy: {
      crop: 'fill',
      gravity: 'auto',
      background: null,
    },
  },
  {
    id: 'lpad',
    name: 'No Crop',
    strategy: {
      crop: 'lpad',
      gravity: null,
      background: 'auto',
    }
  },
];

const Form = ({ sweepstakes, initialInputObject, onSubmit, hasBegun, zone, loading, guarded }) => {
  const dateBlackList = new DateBlacklist(
    sweepstakes
      .flashContests.filter((flashContest) => flashContest.id !== initialInputObject.id)
      .map((flashContest) => [flashContest.beginsAt, flashContest.endsAt])
  );

  const validations = evolve({
    range: validation => combineValidations([validation, dateExclusion(dateBlackList, "This Flash Contest cannot overlap with an existing one")]),
  }, DEFAULT_VALIDATIONS);

  const minimumStartDate = sweepstakes.beginsAt ? DateTime.fromISO(sweepstakes.beginsAt, { zone: sweepstakes.timezone }).toISODate() : null;
  const maximumEndDate = sweepstakes.endsAt ? DateTime.fromISO(sweepstakes.endsAt, { zone: sweepstakes.timezone }).toISODate() : null;

  const handleSubmit = submissionObject => {
    // If the contest starts today or tomorrow, confirm with the user that they want to start the contest.
    // Since the range values are Date's, we are comparing now to midnight of the start date.
    const startsSoon = DateTime.fromISO(submissionObject.range[0], { zone: zone }).diffNow('days').days < 1;

    if(!startsSoon || window.confirm("This contest will automatically start soon. Are you sure you want to save it?")) {
      onSubmit(submissionObject);
    }
  }

  return (
    <LibraryForm initialInputObject={initialInputObject} onSubmit={handleSubmit} validations={validations}>
      <UI.FieldGroup>
        <TextField id="name" label="Name" disabled={guarded} autoFocus />
      </UI.FieldGroup>
      <TextField id="announcement" label="Announcement" />
      <DateRangeField
        id="range"
        label="Contest Dates"
        zone={zone}
        disabled={guarded || hasBegun}
        min={minimumStartDate}
        max={maximumEndDate}
      />
      <ImageUploadField
        id="image"
        label="Image"
        owner={sweepstakes.id}
        directory="contests"
        previewProps={previewProps}
        crops={CROP_OPTIONS}
      />
      <TextField id="caption" label="Caption" />
      <TextField id="notes" label="Notes" />
      <Button type="submit" disabled={loading || guarded}>Save Flash Contest</Button>
    </LibraryForm>
  );
};

export default Form;
