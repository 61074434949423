import React from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { faCircleInfo, faEllipsisV, faSignOutAlt, faQuestionCircle, faShare, faHome } from '@fortawesome/free-solid-svg-icons'

import Button from "../design/Button";
import Link from "../design/Link";
import Menu from "../../lib/Menu";
import useLogout from "../../lib/useLogout";
import * as UI from "./ui";

const UserMenu = ({ nonprofits, currentNonprofit, session }) => {
  const { user }  = useAuth0();
  const logout = useLogout();

  const isAdmin = session?.connection === 'Admin';
  const intent = isAdmin ? 'dangerous' : undefined;

  const label = (
    <Button size="tiny" icon={faEllipsisV} data-intercom-target="User Menu" intent={intent}>
      {user?.name}
    </Button>
  );

  return (
    <UI.Buttons>
      <Routes>
        <Route path="/sweepstakes/:sweepstakesId/promo-codes" element={<UI.TourButton intent="tour" size="tiny" icon={faCircleInfo} onClick={() => window.Intercom('startTour', 551686)}>Take the Tour</UI.TourButton>} />
      </Routes>
      <UI.HelpButton intent="help" size="tiny" icon={faQuestionCircle} onClick={() => window.Intercom('show')}>Help</UI.HelpButton>
      <Menu label={label}>
        <UI.Layout>
          { user.name && <UI.Title>Hello, {user.name}!</UI.Title> }
          <UI.Nonprofits>
            { nonprofits.map((nonprofit) => (
              <UI.Nonprofit key={nonprofit.id}>
                <UI.Info>
                  <UI.NonprofitName>{nonprofit.name}</UI.NonprofitName>
                  <UI.RoleName>{nonprofit.myRole.name}</UI.RoleName>
                </UI.Info>
                <Link size="tiny" to={`/${nonprofit.id}`} icon={(nonprofit.id === currentNonprofit?.id) ? faHome : faShare}>
                  { (nonprofit.id === currentNonprofit?.id) ? "Home" : "Switch" }
                </Link>
              </UI.Nonprofit>
            ))}
          </UI.Nonprofits>
          <UI.Controls>
            <Button intent="help" size="small" icon={faQuestionCircle} onClick={() => window.Intercom('show')}>Help</Button>
            <Button intent="destructive" size="small" onClick={logout} icon={faSignOutAlt}>Sign Out</Button>
          </UI.Controls>
        </UI.Layout>
      </Menu>
    </UI.Buttons>
  );
};

export default UserMenu;
