import React, { useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { prepend, isNil, evolve, path } from "ramda";

import { flashContestsQuery, createFlashContestMutation } from "../gql";
import { Context as SnackbarContext } from "../../../lib/Snackbar";

import FlashContestForm from "../../FlashContestForm";

const prependOnSuccess = sweepstakesId => (cache, results) => {
  const flashContest = path(['data', 'createFlashContest', 'flashContest'], results);

  if(isNil(flashContest)) {
    return;
  }

  const config = {
    query: flashContestsQuery,
    variables: { sweepstakesId },
  };

  const data = cache.readQuery(config);

  const evolution = {
    sweepstakes: {
      flashContests: prepend(flashContest),
    }
  };

  cache.writeQuery({
    ...config,
    data: evolve(evolution, data),
  });
};

const NewForm = ({ guarded, sweepstakes, onSelect }) => {
  const [ createFlashContest, { loading } ] = useMutation(createFlashContestMutation, {
    update: prependOnSuccess(sweepstakes.id),
  });
  const { addMessage } = useContext(SnackbarContext);

  useEffect(() => {
    if (guarded) {
      addMessage({ type: "error", message: "The Sweepstakes has closed so you can not add new Flash Contests." })
    }
  }, [ addMessage, guarded ]);

  const onSubmit = ({ range, ...rest }) => (
    createFlashContest({
      variables: {
        sweepstakesId: sweepstakes.id,
        attributes: {
          ...rest,
          beginsAt: range[0],
          endsAt: range[1],
        }
      },
    }).then(({ data }) => {
      const flashContest = path(['createFlashContest', 'flashContest'], data);

      if(flashContest) {
        onSelect(flashContest.id);
      } else {
        addMessage({ type: "error", message: "An error occurred during form submission." });
      }
    })
  );

  return (
    <React.Fragment>
      <FlashContestForm
        sweepstakes={sweepstakes}
        initialInputObject={{}}
        onSubmit={onSubmit}
        zone={sweepstakes.timezone}
        loading={loading}
        guarded={guarded}
      />
    </React.Fragment>
  );
};

export default NewForm;
