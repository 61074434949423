import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { pipe, pick, evolve, isNil } from "ramda";

import { updateNonprofitMutation, sweepstakesQuery } from "./gql";
import Formation from "../../forms/Form";
import TextField from "../../forms/TextField";
import ImageUploadField from "../../forms/ImageUploadField";
import ColorField from "../../forms/ColorField";
import Button from "../../design/Button";
import { Context } from "../../../lib/Snackbar";
import * as UI from "./ui";

const logoImagePreview = {
  crop: "scale",
  height: 75,
};

const evolveImageType = value => isNil(value) ? value : pipe(
  pick(['cloudName', 'publicId', 'version', 'crop', 'gravity', 'background']),
  JSON.stringify,
)(value);

const buildInitialInputObject = pipe(
  pick(['profile']),
  evolve({
    profile: pipe(
      pick(['cause', 'color', 'logo']),
      evolve({
        logo: evolveImageType,
      }),
    ),
  })
);
const BrandingForm = ({ sweepstakes: { nonprofit }, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ updateNonprofit, { loading } ] = useMutation(updateNonprofitMutation, {
    refetchQueries: [ sweepstakesQuery ],
  })

  const onSubmit = attributes => (
    updateNonprofit({
      variables: {
        id: nonprofit.id,
        attributes,
      }
    }).then(({ data }) => {
      if(isNil(data.updateNonprofit.error)) {
        addMessage({ message: "Your sweepstakes has been successfully updated.", type: "success" });
        onStepFinish();
      }
    })
  );

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <Formation initialInputObject={buildInitialInputObject(nonprofit)} onSubmit={onSubmit} loading={loading}>
          <ImageUploadField id="profile.logo" label="Logo" owner={nonprofit.id} previewProps={logoImagePreview} />
          <UI.FieldGroup>
            <TextField id="profile.cause" label="Short Cause Phrase" labelHint="4-6 words that appear under your nonprofit name in the website header"/>
            <ColorField id="profile.color" label="Brand Color" />
          </UI.FieldGroup>
          <Button type="submit">Save & Continue</Button>
        </Formation>
      </UI.StepContent>
      <UI.Instructions>
        <h2>Nonprofit Branding</h2>
        <p>Upload a <strong>Logo</strong> that will appear in the header next to the name of your nonprofit. The logo will be resized to 75px tall.</p>
        <p>Enter a <strong>Short Cause Phrase</strong> that will appear under your nonprofit name in the website header. This should be 4-6 words that describe your nonprofit's mission.</p>
        <p>Choose a <strong>Brand Color</strong> that will be used for the header background and other elements throughout the website.</p>
        <p><strong>Note:</strong> These settings are shared by all of your sweepstakes. You can change these settings at any time and they will update everywhere.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
};

export default BrandingForm;
