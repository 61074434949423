import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { evolve, pipe, pick, isNil } from "ramda";

import Formation from "../../forms/Form";
import TextField from "../../forms/TextField";
import CurrencyField from "../../forms/CurrencyField";
import Button from "../../design/Button";
import { presence, greaterThanOrEqualTo, between } from "../../../lib/validations";
import { Context } from "../../../lib/Snackbar";
import { updateSweepstakesMutation } from "./gql";
import * as UI from "./ui";

const buildInitialInputObject = pipe(
  pick(['prize', 'threshold']),
  evolve({
    prize: pick(['name','cashAlternative'])
  }),
);

const SweepstakesDetailsForm = ({ sweepstakes, onStepFinish }) => {
  const { addMessage } = useContext(Context);
  const [ updateSweepstakes, { loading }] = useMutation(updateSweepstakesMutation)

  const validations = {
    "prize.name": presence,
    "prize.cashAlternative": greaterThanOrEqualTo(0),
    "threshold": sweepstakes.approved ? between({ min: 0, max: sweepstakes.threshold, message: "Once approved, this minimum can only be lowered." }) : greaterThanOrEqualTo(0),
  };

  const buildSubmissionObject = sweepstakes.approved ?
    pipe(pick(['prize', 'threshold']), evolve({ prize: pick(['name'])})) :
    pipe(pick(['prize', 'threshold']), evolve({ prize: pick(['name', 'cashAlternative'])}));

  const onSubmit = inputObject => (
    updateSweepstakes({
      variables: {
        id: sweepstakes.id,
        attributes: buildSubmissionObject(inputObject),
      }
    }).then(({ data }) => {
      if(isNil(data.updateSweepstakes.error)) {
        addMessage({ message: "Your sweepstakes has been successfully updated.", type: "success" });
        onStepFinish();
      } else {
        addMessage({ message: "There was a problem updating your sweepstakes.", type: "error" });
      }
    })
  );

  return (
    <UI.StepLayout>
      <UI.StepContent>
        <Formation initialInputObject={buildInitialInputObject(sweepstakes)} onSubmit={onSubmit} validations={validations} loading={loading}>
          <TextField id="prize.name" label="Prize Name" disabled={sweepstakes.approved} />
          <CurrencyField id="prize.cashAlternative" label="Grand Prize Cash Option" disabled={sweepstakes.approved} />
          <CurrencyField id="threshold" label="Minimum to Award this Prize" disabled={sweepstakes.approved} />
          <Button type="submit" disabled={sweepstakes.approved}>Save & Continue</Button>
        </Formation>
      </UI.StepContent>
      <UI.Instructions>
        <h2>Understanding Sweepstakes Details</h2>
        <p>Your <strong>Prize Name</strong> should be short. It is primarily used in the FAQ and the rules for this sweepstakes.</p>
        <p>The <strong>Grand Prize Cash Option</strong> is optional. Consider a cash option if the value of your prize is $5,000 or more. Prizes with a value of $5,000 or more are subject to income taxes that your winner may not want to pay. Enter $0 if you do not want to a cash alternative.</p>
        <p>The <strong>Minimum To Award this Prize</strong> is the total dollar amount of donations required to award the grand prize. If the minimum is not met, you will award 25% of the total gross donation proceeds to the qualified selected winner. Enter $0 if you do not want to set a minimum.</p>
        <p><strong>Note:</strong> These values cannot be changed once your sweepstakes has been approved.</p>
      </UI.Instructions>
    </UI.StepLayout>
  );
};

export default SweepstakesDetailsForm;
