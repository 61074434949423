import React from "react";
import { Routes, Route } from "react-router-dom";

import AppShell from "./components/AppShell";
import PublicLayout from "./components/PublicLayout";
import AppLayout from "./components/AppLayout";
import Root from "./components/Root";
import SignIn from "./components/SignIn";
import Dashboard from "./components/Dashboard";
import SweepstakesPages from "./components/SweepstakesPages";
import NonprofitPages from "./components/NonprofitPages";
import ConnectStripe from "./components/ConnectStripe";
import AccountApplicationPage from "./components/AccountApplicationPage";
import InvitationPage from "./components/InvitationPage";

const App = () => (
  <AppShell>
    <Routes>
      <Route element={<PublicLayout />}>
        <Route index element={<Root />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/application/*" element={<AccountApplicationPage />} />
        <Route path="/invitation/:invitationToken" element={<InvitationPage />} />
      </Route>
      <Route element={<AppLayout />}>
        <Route path="stripe" element={<ConnectStripe />} />
      </Route>
      <Route path="/:nonprofitId" element={<AppLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="sweepstakes/:sweepstakesId/*" element={<SweepstakesPages />} />
        <Route path="nonprofit/*" element={<NonprofitPages />} />
      </Route>
    </Routes>
  </AppShell>
);

export default App;
