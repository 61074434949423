import styled from "styled-components";
import { Image } from "cloudinary-react";

export const Layout = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (min-width: 816px) {
    display: grid;
    gap: 24px;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      "at-a-glance hints"
      "promo-codes recent-donations"
      "flash-contest marketing";
  }
`;

export const DrawnLayout = styled(Layout)`
  @media (min-width: 816px) {
    grid-template-areas:
      "retro winner"
      "at-a-glance hints"
  }
`;

export const Section = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const SectionHeading = styled.div`
  font-weight: bold;
  font-size: 0.9375em;
  color: rgba(0, 0, 0, 0.8);
  padding: 0 6px;
  font-family: tondo, sans-serif;

  @media (min-width: 816px) {
    font-size: 1.125em;
  }
`;

export const HintLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const HintBody = styled.div`
  background-color: #ffffff;
  border-radius: 3px;
  padding: 12px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  gap: 6px;

  ul {
    list-style: disc;
    padding-left: 24px;
  }
`;

export const HintHeading = styled.div`
  font-weight: bold;
  font-size: 0.875em;
`;

export const HintText = styled.div`
  font-size: 0.875em;
`;

export const Subsection = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-start;
`;

export const SubsectionContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Slide = styled(Image)`
  display: block;
  border-radius: 6px;

  @media (max-width: 815px) {
    display: none;
  }
`;
