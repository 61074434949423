import React, { useState } from "react";
import { pipe, defaultTo, map, pick, evolve } from "ramda";

import { formatAsDollars } from "../../util/money";
import useLocalStorage from "../../lib/useLocalStorage";

import Form from "../forms/Form";
import TextField from "../forms/TextField";
import SelectField from "../forms/SelectField";
import CountrySelectField from "../forms/CountrySelectField";
import PaymentMethodSelectField from "../forms/PaymentMethodSelectField";
import ClearFieldCheckbox from "../forms/ClearFieldCheckbox";
import Button from "../design/Button";

import * as UI from "./ui";

const DEFAULT = {
  address: {
    country: 'US',
    state: '',
    postalCode: '',
  }
};

const buildEntryInput = pipe(
  defaultTo(DEFAULT),
  pick(['promoCodeName', 'notes', 'firstName', 'lastName', 'phoneNumber', 'email', 'address']),
  evolve({
    address: pick([
      "line1",
      "line2",
      "city",
      "state",
      "postalCode",
      "country",
    ])
  }),
);

const buildEntryLevelOptions = map(({id, level, price,rewardTickets }) => ({
  // TODO: The API currently accepts the level, not the ID.
  id: level,
  name: `Level ${level} - ${formatAsDollars(price)} - ${rewardTickets} tickets`,
}));

const buildPromoCodeOptions = map(({name, bonusPercent}) => ({
  id: name,
  name: `${name} - ${bonusPercent}%`
}));

const LAST_PROMO_CODE_EXPIRES_IN = 12 * 60 * 60;

const EntryForm = ({ entry, sweepstakes, onSubmit, loading, guarded, validations, ...rest}) => {
  const [lastPromoCode, setLastPromoCode] = useLocalStorage(undefined, `${sweepstakes.id}-lastSelectedPromoCode`, {
    expiresIn: LAST_PROMO_CODE_EXPIRES_IN
  });

  const newEntry = !entry?.id;
  const existingEntryWithoutEmail = !newEntry && !entry.email;
  const [noEmail, setNoEmail] = useState(existingEntryWithoutEmail);

  const initialInputObject = buildEntryInput(entry);
  if(newEntry) {
    initialInputObject.promoCodeName = lastPromoCode;
  }

  const modifiedValidations = {
    ...validations,
    email: noEmail ? null : validations.email,
  };

  const handleSubmit = (values) => {
    if (newEntry) {
      setLastPromoCode(values['promoCodeName']);
    }
    onSubmit(values);
  }

  return (
    <Form
      initialInputObject={initialInputObject}
      onSubmit={handleSubmit}
      validations={modifiedValidations}
      {...rest}
    >
      <UI.FieldGroup>
        <TextField id="firstName" label="First Name" disabled={guarded} autoFocus />
        <TextField id="lastName" label="Last Name"disabled={guarded} />
      </UI.FieldGroup>
      <UI.FieldGroup>
        <TextField id="address.line1" label="Address Line 1" disabled={guarded} />
        <TextField id="address.line2" label="Address Line 2" disabled={guarded} />
      </UI.FieldGroup>
      <TextField id="address.city" label="City" disabled={guarded} />
      <UI.FieldGroup>
        <TextField id="address.state" label="State" disabled={guarded} />
        <TextField id="address.postalCode" label="Zip Code" disabled={guarded} />
      </UI.FieldGroup>
      <CountrySelectField id="address.country" label="Country" disabled={guarded} />
      <TextField id="email" label="Email" type="email" disabled={guarded || noEmail} />
      { (newEntry || existingEntryWithoutEmail) && (
        <ClearFieldCheckbox
          id="email"
          label="I do not have an email address for this entry, send me the receipt instead."
          onChange={setNoEmail}
          defaultChecked={existingEntryWithoutEmail}
        />
      )}
      <TextField id="phoneNumber" label="Phone" disabled={guarded} />
      { newEntry && (
        <React.Fragment>
          <UI.FieldGroup>
            <SelectField id="donationLevel" label="Level" options={buildEntryLevelOptions(sweepstakes.donationLevels)} allowBlank disabled={guarded} />
            <PaymentMethodSelectField id="paymentMethod" label="Payment Method" allowBlank disabled={guarded} />
          </UI.FieldGroup>
          <SelectField id="promoCodeName" label="Promo Code" options={buildPromoCodeOptions(sweepstakes.promoCodes)} allowBlank disabled={guarded} />
        </React.Fragment>
      )}
      <UI.FieldGroup>
        <TextField id="notes" label="Notes" disabled={guarded} />
      </UI.FieldGroup>
      <UI.FormFooter>
        <Button type="submit" value="Save Donation" disabled={loading || guarded}>Save Entry</Button>
      </UI.FormFooter>
    </Form>
  );
}

export default EntryForm;
