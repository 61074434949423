import React from "react";
import styled from "styled-components";

import Money from "./Money";

const Stats = styled.div`
  flex: 1;
  display: flex;
  font-weight: bold;
  gap: 6px;

  ${props => props.$size === "medium" && `
    font-size: 0.75em;
  `}

  ${props => props.$size === "large" && `
    font-size: 1em;
  `}

  @media (min-width: 1024px) {
    font-size: 1em;
  }
`;

const Stat = styled.div`
  color: #ffffff;
  white-space: nowrap;
  border-radius: 3px;
  flex: 1;
  text-align: center;

  ${props => props.$size === "medium" && `
    padding: 2px 6px;
  `}

  ${props => props.$size === "large" && `
    padding: 6px 12px;
  `}

  ${props => props.$disabled && `
    filter: grayscale(1);
  `}
`;

const Total = styled(Stat)`
  background-color: #77aa16;
`;

const Average = styled(Stat)`
  background-color: #7d5ea3;
`;

const Count = styled(Stat)`
  background-color: #375BA4;
`;

const StatSummary = ({ count, average, total, size }) => (
  <Stats $size={size} data-intercom-target="Stat Summary">
    <Total $size={size} $disabled={total === 0}><Money amount={total} /></Total>
    <Count $size={size} $disabled={count === 0}>{count} Donations</Count>
    <Average $size={size} $disabled={average === 0}><Money amount={average} /> Avg</Average>
  </Stats>
);

StatSummary.defaultProps = {
  size: "medium",
};

export default StatSummary;
