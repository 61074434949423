import { gql } from "@apollo/client";

export const drawFlashContestWinnerMutation = gql`
  mutation DrawFlashContestWinner($id: ID!) {
    drawFlashContestWinner(id: $id) {
      flashContest {
        id
        flashContestWin {
          id
          confirmed
        }
        winningTicket {
          id
          number
        }
        winningEntry {
          id
          firstName
          lastName
          email
          phoneNumber
          address {
            city
            state
          }
        }
      }

      error {
        code
        message
      }
    }
  }
`;

export const confirmFlashContestWinnerMutation = gql`
  mutation ConfirmFlashContestWinner($id: ID!) {
    confirmFlashContestWinner(id: $id) {
      flashContest {
        id
        flashContestWin {
          id
          confirmed
        }
      }

      error {
        code
        message
      }
    }
  }
`;
