import React from "react";

import { default as LibraryForm } from "../forms/Form";
import useField from "../../lib/useField";
import Button from "../design/Button";
import TextField from "../forms/TextField";
import DateField from "../forms/DateField";
import BonusPercentageSelectField from "../forms/BonusPercentageSelectField";
import DonationLevels from "../DonationLevels";

import { formatOf, lengthOf, numericality } from "../../lib/validations";

const validations = {
  name: formatOf({ pattern: /^[A-Za-z0-9_-]+$/, message: "This field must only contain letters, numbers, hyphens, or underscores."  }),
  bonusPercent: numericality,
  notes: lengthOf({maximum: 1024}),
};

const Preview = ({ sweepstakesId }) => {
  const { value } = useField("bonusPercent");
  return <DonationLevels sweepstakesId={sweepstakesId} promoCode={{bonusPercent: value}} />;
};

const Form = ({sweepstakesId, initialInputObject, onSubmit, inUse, zone, legacyValue, loading, guarded}) => (
  <LibraryForm initialInputObject={initialInputObject} onSubmit={onSubmit} validations={validations}>
    <TextField id="name" label="Promo Code" disabled={inUse || guarded} autoFocus />
    <BonusPercentageSelectField
      id="bonusPercent"
      label="Promo Entry Percentage"
      inUse={inUse}
      legacyValue={legacyValue}
      allowBlank
      disabled={guarded}
    />
    <DateField
      id="expirationDate"
      label="Expiration Date"
      labelHint="Optional"
      zone={zone}
      disabled={guarded}
    />
    <TextField id="notes" label="Notes" labelHint="Optional" disabled={guarded} />
    <Button type="submit" disabled={loading || guarded}>Save Promo Code</Button>
    <Preview sweepstakesId={sweepstakesId} />
  </LibraryForm>
);

export default Form;
