import styled from "styled-components";

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PreviewLayout = styled.div`
  margin: 20px 0;
`;
