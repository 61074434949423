import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { faCheck, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import Snackbar from "../../Snackbar";
import Button, { disabledValue } from "../../design/Button";
import { requestSweepstakesApprovalMutation } from "./gql";
import * as UI from "./ui";

const CONFIRMATION = "Once we approve your sweepstakes site, you will no longer be able to change your donation amounts, or your close date. Are you sure you’re ready to to go live with your sweepstakes?";

const RequestApproval = ({ sweepstakes, disabled }) => {
  const [requested, setRequested] = useState(false);
  const [requestSweepstakesApproval, { data, loading }] = useMutation(requestSweepstakesApprovalMutation, {
    variables: {
      id: sweepstakes.id,
    },
  });

  const onRequest = e => {
    e.preventDefault();

    if(window.confirm(CONFIRMATION)) {
      requestSweepstakesApproval().then(({ data }) => {
        if (!data.requestSweepstakesApproval.error) {
          setRequested(true);
        }
      });
    }
  };

  if (sweepstakes.approved) {
    return null;
  }

  const error = data && data.requestSweepstakesApproval.error

  const icon = requested => requested ? faCheckCircle : faCheck;

  return (
    <div>
      <UI.RequestApprovalHeading>Ready to Start Your Sweepstakes?</UI.RequestApprovalHeading>
      <Button
        icon={icon(requested)}
        onClick={onRequest}
        disabled={disabledValue({ loading, success: requested, disabled })}
      >
        {requested ? "Requested!" : "Request Approval"}
      </Button>
      {error && <Snackbar type="error" message={error.message} />}
    </div>
  );
};

export default RequestApproval;
