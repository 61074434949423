import styled from "styled-components";

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Legal = styled.p`
  padding: 0 10px;
  font-size: 0.875em;
`
export const Fieldset = styled.div`
`;

export const Legend = styled.legend`
  color: #000;
  margin: 5px;
  padding: 10px 0;
  border-bottom: 3px solid #eee;
  font-weight: bold;
`;

export const Hint = styled.div`
  padding: 0 10px;
  font-size: 0.875em;
`;

