import React from "react";
import { useQuery } from "@apollo/client";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import Link from "../design/Link";
import { blogPostsQuery } from "./gql";
import * as UI from "./ui";

const Hints = () => {
  const { data, loading, error } = useQuery(blogPostsQuery);

  if(loading || error) {
    return null;
  }

  const blogPost = data?.blogPosts[Math.floor(Math.random() * data.blogPosts.length)];

  if(!blogPost) {
    return null;
  }

  return (
    <UI.Section style={{ gridArea: "hints" }}>
      <UI.SectionHeading>Helpful Hints</UI.SectionHeading>
      <UI.HintLayout>
        <UI.HintBody>
          <UI.HintHeading>{blogPost.title}</UI.HintHeading>
          <UI.HintText>{blogPost.excerpt}</UI.HintText>
          <Link href={blogPost.url} icon={faExternalLinkAlt} size="small" style={{ alignSelf: 'flex-end'}}>Read More</Link>
        </UI.HintBody>
      </UI.HintLayout>
    </UI.Section>
  );
}

export default Hints;
