import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { includes, pathOr, filter } from "ramda";

import MasterDetail from "../../lib/MasterDetail";
import ExportButton from "../ExportButton";
import { useSchedulePeriod } from "../../lib/schedule";
import { sweepstakesPromoCodesQuery } from "./gql";
import Item from "./Item";
import Detail from "./Detail";
import NoSelection from "./NoSelection";

const PromoCodeList = ({ sweepstakesId }) => {
  const [ searchInput, setSearchInput ] = useState(null);

  const period = useSchedulePeriod();
  const newItemEnabled = ["review", "open", "closing"].includes(period);

  const { loading, data } = useQuery(sweepstakesPromoCodesQuery, {
    variables: {
      sweepstakesId,
    }
  });

  const promoCodes = pathOr([], ["sweepstakes","promoCodes"], data);
  const sweepstakes = pathOr([], ["sweepstakes"], data);

  const search = {
    auto: true,
    placeholder: "Search by name…",
    onSubmit: setSearchInput,
    filters: [
      { id: "ALL", name: "All"  },
      { id: 20,    name: "20%"  },
      { id: 25,    name: "25%"  },
      { id: 30,    name: "30%"  },
      { id: 35,    name: "35%"  },
      { id: 40,    name: "40%"  },
      { id: 50,    name: "50%"  },
      { id: 100,   name: "100%" },
    ],
    defaultFilter: "ALL",
  };

  const searchFilter = promoCode => {
    const substringMatch = searchInput.value ?
      includes(searchInput.value.toLowerCase(), promoCode.name.toLowerCase()) : true;

    const filterMatch = searchInput.filter.id === "ALL" ?
      true : searchInput.filter.id === promoCode.bonusPercent;

    return substringMatch && filterMatch;
  };

  const searchedPromoCodes = searchInput ? filter(searchFilter, promoCodes) : promoCodes;
  const newItem = {
    label: "Add Promo Code",
    resource: "promoCodes",
    permission: "C",
    disabled: !newItemEnabled,
  };

  const footerMenu = (
    <ExportButton url={`/exports/${sweepstakesId}/promo_codes.csv`} resource="promoCodes">
      Promo Code List
    </ExportButton>
  );

  return (
    <MasterDetail
      items={searchedPromoCodes}
      loading={loading}
      search={search}
      ListItemComponent={Item}
      DetailComponent={Detail}
      NoSelectionComponent={NoSelection}
      newItem={newItem}
      footerMenu={footerMenu}
      sweepstakes={sweepstakes}
    />
  );
}

export default PromoCodeList;
