import React from "react";

import useField from "../../lib/useField";
import * as UI from "./ui";

const TextField = ({id, label, labelHint, ...rest}) => {
  const { value, errors, showErrors, skeleton, setValue, touch } = useField(id);

  return (
    <UI.FieldLayout>
      <UI.Input
        id={id}
        name={id}
        type="text"
        onChange={setValue}
        onBlur={touch}
        value={value}
        disabled={skeleton}
        invalid={showErrors}
        data-intercom-target={`${id}*`}
        {...rest}
      />
      <UI.Label htmlFor={id} labelHint={labelHint}>{label}</UI.Label>
      { showErrors && <UI.Errors>{errors}</UI.Errors> }
    </UI.FieldLayout>
  );
};

export default TextField;
